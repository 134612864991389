import React from "react";
import "./Products.scss";
import "./Lightbox";
import LightboxExample from "./Lightbox";

function Products(props) {  
  return (
    // <section className={"products-component about-area pt-140 pb-140 " + ( props.isEven ? "bg-cdcy-light" : "bg-cdcy-dark" ) }>
    <section className={"products-component about-area pt-140 pb-140 " + ( props.isEven ? "bg-white" : "bg-light" ) }>
      <div className="container">
        <div className={"align-items-center " + ( props.isEven ? "row flex-row-reverse" : "row" ) }>
          <div className="col-lg-6">
            <div className="about-image product">
              <img src={"/img/" + props.data.img} alt="service"  />
              {/* <LightboxExample /> */}
            </div>
          </div>
          <div className="col-lg-6 text-center pl-5 pr-5">
            <div className="about-content">
              <h3 className="title mb-30">{props.data.title}</h3>
              <p className="text">{props.data.text}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Products
