import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import PAGEDATA from "../../content/product.json";
// import ProductList from "../components/ProductList";
import Products from "../components/Products";
import heroSvg from "../img/undraw/undraw_file_analysis_8k9b.svg";
import PageWrapper from "../PageWrapper";

//                     { data, location }
const ProductIndex = ( { data } ) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <PageWrapper>
      <Header component={ <Hero bgImage={ heroSvg } /> } title={ siteTitle } />
      <SEO title="Cloud-based QA - lexiQA" />
      <SEO description="API-based QA solution in the cloud that can be customized to fit your translation needs" />

      {/* <!--====== SERVICE PART START ======--> */ }

      {/* <section className="services-area-3 bg-light bg_cover">
        <div className="container">
          <div className="services-3 pt-115 pb-95">
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-8 col-sm-9">
                <div className="section-title section-title-2 text-center pb-20">
                  <h4 className="title">TRANSLATION QA MADE EASY</h4>
                  <h6 className="sub-title product mt-3">Zero learning curve - 100% customisable</h6>
                </div>
              </div>
            </div>            
            <ProductList data={ PAGEDATA.productlist } />
          </div>
        </div>
      </section> */}

      {/* <!--====== SERVICE PART ENDS ======--> */ }

      {/* <!--====== PRODUCT ROW START ======--> */ }

      { PAGEDATA.product.map( ( val, index ) => {
        const isEven = index % 2 === 0
        return <Products isEven={ isEven } data={ val } key={ val.title } />
      } ) }

      {/* <!--====== PRODUCT ROW END ======--> */ }


      <Footer />
    </PageWrapper>
  )
}

export default ProductIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
