import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const images = [
    '/img/lxq-editor.gif',
    '/img/report-table.png',
    '/img/terminology.png',
    '/img/untranslatables.png',
    '/img/revision-history.png',
    '/img/matecat-1.png',
    '/img/transifex-editor.png',
    '/img/memsource-video-teaser-img-min.png'
  ]

export default class LightboxExample extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <div>
        {/* <img src='' alt="service"  /> */}
        <button type="button" className="lightbox-btn" onClick={() => this.setState({ isOpen: true })}>
          Zoom in
        </button>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>     
    );
  }
}